export function formatDate(dateString){
    const date = new Date(dateString);
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false, // Use 24-hour format
    };
    return new Intl.DateTimeFormat('en-GB', options).format(date).replace(',', '');
};

export default formatDate;