import { apiRequest } from './apiClientDummy';
import { formatDate } from '../helpers/dateFormatter';

const ARCHIVE_API = '';

export const getNowPlaying = async (token) => {
    var x = await apiRequest(`${ARCHIVE_API}`, 'GET', null, token);
    
    if(x.random != null)
    {
        var artistName = x.random.artist.artistName;
        return {
            artist: artistName,
            artistId: x.random.artist.id,
            isLive: true,
            isArchive: false
        }
    }
    else if(x.autoDj != null)
    {
        var artistNme = x.autoDj.archivedMix.artistObject.artistName;
        var recorded = x.autoDj.archivedMix.recorded;
        return {
            artist: artistNme,
            recorded: formatDate(recorded),
            artistId: x.autoDj.archivedMix.artistId,
            isLive: false,
            isArchive: true
        }
    }
    else{
        return {
            aritist: "",
            artistId: undefined,
            isLive: false,
            isArchive: false
        }
    }
};
