// FileName: data.js

import { v4 as uuidv4 } from "uuid";
function chillHop() {
  return [
    {
        name: "Arc Radio - Live Stream",
        cover: "https://www.arc-radio.net/assets/assets/images/Plain.png",
        artist: "Arc Radio",
        audio: "https://www.arc-radio.net:8001/output.ogg",
        color: ["#4B0082", "#000000"],
        id: -1,
        active: true
    }
  ];
}

export default chillHop;
