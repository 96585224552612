import React, { useEffect, useState } from "react";
import LibrarySong from "./LibrarySong";
import LiveSong from './LiveSong';
import { getFitleredArchiveItems } from '../services/musicService';
import { getArtists } from '../services/artistService';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

// Import a loading spinner, e.g. a simple CSS spinner or a library like react-spinners
import { RingLoader } from "react-spinners";  // Or create your own spinner
import { getNowPlaying } from "../services/nowPlayingService";
import formatDate from "../helpers/dateFormatter";

const Library = ({
    songs,
    setCurrentSong,
    audioRef,
    isPlaying,
    setSongs,
    setLibraryStatus,
    libraryStatus,
    setPlayLiveSong
}) => {
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [lastPage, setLastPage] = useState(false);
    const [pageSize] = useState(10); // Adjust page size as needed
    const [artists, setArtists] = useState([]);
    const [selectedArtist, setSelectedArtist] = useState("");
    const [liveSongMetadata, setLiveSongMetadata] = useState(null); // State for live song metadata
    const [songsLoading, setSongsLoading] = useState(true); // New state to track loading state of songs

    useEffect(() => {
        async function fetchArtists() {
            try {
                const artistData = await getArtists();
                setArtists(artistData);
            } catch (err) {
                console.error("Error fetching artists:", err);
            }
        }
        fetchArtists();
    }, []);

    useEffect(() => {
        async function fetchNowPlaying() {
            try
            {
                const data = await getNowPlaying();
                var o = {
                    id: -1,
                    song: data.artist || "Live Stream", // Default to "Live Stream" if empty
                    artist: data.artist || "Arc Radio", // Default to "Arc Radio"
                    artistId: data.artistId || -1,
                    recorded: data.recorded || "",
                    isLive: data.isLive || false,
                    isArchive: data.isArchive || true
                };
                setLiveSongMetadata(data);
                setPlayLiveSong(o);
            }
            catch
            {
                
            }
        }

        fetchNowPlaying(); // Initial fetch when the component mounts

        // Fetch metadata every 10 seconds to keep it up-to-date
        const interval = setInterval(fetchNowPlaying, 10000); // Fetch every 10 seconds

        return () => clearInterval(interval); // Cleanup on unmount
    }, [])
    
    useEffect(() => {
        async function fetchData() {
            try {
                setSongsLoading(true);  // Set songs loading state to true
                const data = await getFitleredArchiveItems("", selectedArtist, page, pageSize);
                // Start with the default item
                const newSongs = [liveStreamSong];
                data.data.map(d => {
                    var imgUrl = `https://www.arc-radio.net/assets/artists/${d.artistObject.id}_300.png`
                    newSongs.push({
                        id: d.id || Math.random().toString(36).substr(2, 9), // Ensure unique IDs
                        cover: imgUrl,
                        artist: d.artistObject.artistName,
                        recorded: formatDate(d.recorded),
                        audio: `https://www.arc-radio.net/assets/hive/${d.filename}`,
                        color: ["#4B0082", "#000000"],
                        active: false,
                        isLive: false,
                        isArchive: true
                    })
                })
                setLastPage(data.lastPage);
                setTotalPages(data.totalSize / data.pageSize);
                setSongs(newSongs);
            } catch (err) {
                setError(err.message);
            } finally {
                setSongsLoading(false);  // Set songs loading state to false once done
            }
        }
        fetchData();
    },[page, pageSize, selectedArtist]); 

    if (error) return <p>Error: {error}</p>;
    var coverImg = liveSongMetadata?.artistId !== undefined ? 
    `https://www.arc-radio.net/assets/artists/${liveSongMetadata?.artistId}_300.png` :
    "/Plain.png";
    const liveStreamSong = {
        name: liveSongMetadata?.song || "Live Stream",
        cover: coverImg,
        artist: liveSongMetadata?.artist || "Arc Radio",
        recorded: liveSongMetadata?.recorded,
        isLive: liveSongMetadata?.isLive || false,
        audio: "https://www.arc-radio.net:8001/output.ogg",
        color: ["#4B0082", "#000000"],
        id: -1,
        active: false,
        isArchive: liveSongMetadata?.isArchive
    };

    return (
        <div className={`library ${libraryStatus ? "active" : ""}`}>
            <LiveSong
                key=""
                audioRef={audioRef}
                songs={songs}
                setSongs={setSongs}
                song={liveStreamSong}
                setCurrentSong={setCurrentSong}
                id={liveStreamSong.id}
                liveSong={liveStreamSong}
                setLibraryStatus={setLibraryStatus}
            />
            
            {/* The artist filter section */}
            <div className="filter-section">
                <select id="artist-filter" className="artist-filter" value={selectedArtist} onChange={(e) => {
                    setPage(1);
                    setSelectedArtist(e.target.value);
                }}>
                    <option value="">All Artists</option>
                    {artists.map((artist) => (
                        <option key={artist.id} value={artist.id}>{artist.artistName}</option>
                    ))}
                </select>
            </div>

            {/* The pagination controls */}
            <div className="pagination-controls">
                <div className="pagination-actions">
                    <FontAwesomeIcon
                        onClick={() => setPage(prev => Math.max(prev - 1, 1))}
                        size="2x"
                        className="skip-back"
                        icon={faAngleLeft}
                        disabled={page === 1}
                    />
                    <span className="pageCount">
                        Page {page} / {Math.floor(totalPages)}
                    </span>
                    <FontAwesomeIcon
                        onClick={() => setPage(prev => prev + 1)}
                        size="2x"
                        className="skip-forward"
                        icon={faAngleRight}
                        disabled={lastPage}
                    />
                </div>
            </div>

            {/* Songs section with conditional loading spinner */}
            <div className="library-songs">
                {songsLoading ? (
                    <div className="loading-spinner">
                        <RingLoader color="white" loading={songsLoading} size={60} />
                    </div>
                ) : (
                    songs.map((song) => {
                        if(song.id !== -1)
                        {
                            return <LibrarySong
                                key={song.id}
                                setSongs={setSongs}
                                isPlaying={isPlaying}
                                audioRef={audioRef}
                                songs={songs}
                                song={song}
                                setCurrentSong={setCurrentSong}
                                id={song.id}
                                liveSong={liveStreamSong}
                                setLibraryStatus={setLibraryStatus}
                            />
                        }
                        else{
                            return "";
                        }
                    })
                )}
            </div>
        </div>
    );
};

export default Library;
