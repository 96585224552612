import { apiRequest } from './apiClient';

const ARCHIVE_API = 'service/Music/';

export const getArchiveItems = async (token, page = 0, size = 10) => {
    return apiRequest(`${ARCHIVE_API}archive?page=${page}&size=${size}`, 'GET', null, token);
};

export const getFitleredArchiveItems = async (token, selectedArtist = null, page = 0, pageSize = 10) => {
    var searchObject = {
        "artistId": selectedArtist === "" ? null : selectedArtist,
        "favouritesOnly": false,
        "pageNumber": page,
        "pageSize": pageSize
    }
    var result = apiRequest(`${ARCHIVE_API}searcharchive`, 'POST', searchObject, token);
    return result;
}
