export async function apiRequest(endpoint, method = 'GET', body = null, token = null) {
    const headers = { 'Content-Type': 'application/json' };
    const baseUrl = "https://www.arc-radio.net:7503"
    if (token) headers['Authorization'] = `Bearer ${token}`;
    var x = `${baseUrl}/${endpoint}`;
    const response = await fetch(x, {
        method,
        headers,
        body: body ? JSON.stringify(body) : null
    });

    if (!response.ok) throw new Error(`API error: ${response.status}`);
    return response.json();
}
