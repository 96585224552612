// FileName: LibrarySong.js

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDownload
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
const LibrarySong = ({
    song,
    songs,
    setCurrentSong,
    audioRef,
    isPlaying,
    setSongs,
    id,
    setLibraryStatus
}) => {
    const songSelectHandler = async () => {
        await setCurrentSong(song);
        //active
        const newSongs = songs.map((song) => {
            if (song.id === id) {
                return {
                    ...song,
                    active: true,
                };
            } else {
                return {
                    ...song,
                    active: false,
                };
            }
        });
        setSongs(newSongs);
        audioRef.current.play();
        setLibraryStatus();
    };
    return (
        <div
            onClick={songSelectHandler}
            className={`library-song ${song.active ? "selected" : ""}`}
        >
            <img src={song.cover} alt={song.name} />
            <div className="song-description">
                <h3>{song.artist}</h3>
                <h4>{song.recorded}</h4>
            </div>  
            <div classname="song-download">
                <FontAwesomeIcon
                    onClick={(() => {
                        const fileUrl = song.audio;
                        const link = document.createElement("a");
                        link.href = fileUrl;
                        link.setAttribute("target", "_blank");
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    })}
                    size="1x"
                    className="downloadButton"
                    icon={faDownload}
                />
            </div>
        </div>
    );
};

export default LibrarySong;
