// FileName: LiveSong.js
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDownload
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
const LiveSong = ({
    song,
    songs,
    setCurrentSong,
    audioRef,
    isPlaying,
    setSongs,
    id,
    setLibraryStatus
}) => {
    
    const songSelectHandler = async () => {
        //active
        const newSongs = songs.map((sng) => {
            if(sng.id === -1) {
                song.cover = "/Plain.png";
                song.active = true;
            }
            if (sng.id === id || sng.id === -1) {
                return {
                    ...sng,
                    active: true,
                };
            } else {
                return {
                    ...sng,
                    active: false,
                };
            }
        });
        await setCurrentSong(song);
        setSongs(newSongs);

        audioRef.current.play();
        setLibraryStatus();
    };

    return (
        <div
            onClick={songSelectHandler}
            className={`library-song ${song.isLive ? "library-song-live" : ""} ${song.active ? "selected" : ""}`}
        >
            <img src={song.cover} alt={song.name} />
            <div className="song-description">
                <h4 className="indicator">Live Stream - {song.isLive ? "LIVE" : "Archive"}</h4>
                <h3>{song.artist}</h3>
                <h4 className="song-description-recorded">{song.recorded}</h4>
            </div>
            <div className="song-download">
                <FontAwesomeIcon
                    onClick={() => {
                        const fileUrl = song.audio;
                        const link = document.createElement("a");
                        link.href = fileUrl;
                        link.setAttribute("target", "_blank");
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }}
                    size="1x"
                    className="downloadButton"
                    icon={faDownload}
                />
            </div>
        </div>
    );
};

export default LiveSong;
