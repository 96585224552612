// FileName: Navb.js

import React from "react";

const Nav = ({ setLibraryStatus, libraryStatus }) => {
    return (
        <nav>
            <div>
                <button
                    onClick={() => {
                        setLibraryStatus(!libraryStatus);
                    }}
                >
                    <h4>Library</h4>
                </button>
            </div>
            
        </nav>
    );
};

export default Nav;
