
const Chat = ({
    chatVisible
}) => {
    var server = "irc.arc-radio.net";
    var channel = "#arcradio";
    const kiwiURL = `https://www.arc-radio.net:7778?server=${server}&theme=cli${channel}`;

  return (
    <div className="w-full h-full border rounded-lg overflow-hidden song-container">
      <iframe
        src={kiwiURL}
        title="KiwiIRC"
        className="w-full h-full border-none wfull song-container"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Chat;